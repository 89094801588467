import { loadScript, type PayPalNamespace } from "@paypal/paypal-js";

type PickRequired<Type, Keys extends keyof Type> = Type &
  Required<Pick<Type, Keys>>;

let payPalNamespace: Promise<
  PickRequired<PayPalNamespace, "Buttons" | "CardFields">
> | null = null;

const PAY_PAL_DEFAULT_PAYMENT_HANDLER = "pay_pal_payment_handler";
const PAY_PAL_CREDIT_CART_PAYMENT_STORT_NAME = "a_c_d_c_handler";
const PAY_PAL_PAY_UPON_INVOICE_SHORT_NAME = "p_u_i_handler";

export function usePayPal() {
  const runtimeConfig = useRuntimeConfig();
  const { currencyCode } = usePrice();
  const { locale } = useI18n();

  function loadPayPalNamespace() {
    if (payPalNamespace === null) {
      payPalNamespace = loadScript({
        clientId: runtimeConfig.public.paypal.clientId,
        currency: currencyCode.value,
        locale: locale.value.replace("-", "_"),
        components: ["buttons", "card-fields", "legal"],
      }).then((namespace) => {
        // ensure namespace was loaded
        if (namespace === null) throw new Error("Error loading PayPal");
        return namespace as PickRequired<
          PayPalNamespace,
          "Buttons" | "CardFields"
        >;
      });
    }

    return payPalNamespace;
  }

  function insertFraudNetScripts(
    sessionId: string,
    pageId:
      | "home-page"
      | "search-result-page"
      | "category-page"
      | "product-detail-page"
      | "cart-page"
      | "inline-cart-page"
      | "checkout-page",
  ) {
    const config = {
      f: sessionId,
      s: `${runtimeConfig.public.paypal.merchantId}_${pageId}`,
      sandbox: runtimeConfig.public.paypal.sandbox,
    };

    const configTag = document.createElement("script");
    configTag.type = "application/json";
    configTag.setAttribute(
      "fncls",
      "fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99",
    );
    configTag.innerText = JSON.stringify(config);
    document.head.insertAdjacentElement("beforeend", configTag);

    const scriptTag = document.createElement("script");
    scriptTag.src = "https://c.paypal.com/da/r/fb.js";
    document.head.insertAdjacentElement("beforeend", scriptTag);
  }

  return {
    loadPayPalNamespace,
    insertFraudNetScripts,
    PAY_PAL_DEFAULT_PAYMENT_HANDLER,
    PAY_PAL_CREDIT_CART_PAYMENT_STORT_NAME,
    PAY_PAL_PAY_UPON_INVOICE_SHORT_NAME,
  };
}
